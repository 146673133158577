// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { NFTContractType, NetworkEnvironment, NetworkType, NetworkChain } = HyperMint;

// Deployed Mumbai ERC721 Contract
export default {
    contractId: '162efe2a-948a-4545-9395-132d3b0860b3',
    contractAddress: '0x6c23da15116a4036500812c2a062b1219f4bf80b',
    contractType: NFTContractType.ERC721,
    networkEnvironment: NetworkEnvironment.Mainnet,
    networkType: NetworkType.Polygon,
    networkChain: NetworkChain.Polygon,
    enableLogging: true
};

// Deployed Mumbai ERC1155 Contract
// export default {
//     contractId: 'a56134e9-a8ef-43a6-a257-3f9cd8be6b96',
//     contractAddress: '0x54f8DbB6960720332991b53Dda3F3c56Ed609106',
//     contractType: NFTContractType.ERC1155,
//     networkEnvironment: NetworkEnvironment.Testnet,
//     networkType: NetworkType.Polygon,
//     networkChain: NetworkChain.Mumbai,
//     enableLogging: true
// };